import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

type CardProps = {
	title: string;
	description: string;
	imageUrl: string;
	iconUrl: string;
};

const Card: React.FC<CardProps> = (props) => {

	const navigate = useNavigate();

	const handleReadMore = () => {
		navigate('/ServiceDetail', { state: { props } });
	};

	return (
		<div className="relative flex flex-col justify-between items-center mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl group hover:bg-[#001f3f] hover:text-white transition-colors duration-300">
			<div className='p-6 pb-0'>
				<img src={props.iconUrl} alt="Dynamic" className=" h-16 w-16" />
			</div>
			<div className="p-6">
				<h5 className="py-0 px-8 md:text-lg text-center mb-5 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900 group-hover:text-white">
					{props.title}
				</h5>
				<p className="py-0 px-2 text-center line-clamp-3 font-sans text-sm text-base antialiased font-light leading-relaxed text-inherit group-hover:text-white" style={{
					display: '-webkit-box',
					WebkitBoxOrient: 'vertical',
					WebkitLineClamp: 3,
					overflow: 'hidden',
					textOverflow: 'ellipsis'
				}}>
					{props.description}
				</p>
			</div>
			<div className="p-6 pt-0  cursor-pointer">
				<button
					className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-2 md:py-3 px-4 md:px-6 rounded-lg bg-[#603F83] text-white shadow-md shadow-gray-900/10 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
					type="button"
					onClick={handleReadMore}>
					Read More
				</button>
			</div>
		</div>

	);
};

export default Card;