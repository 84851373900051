// No need to import React if using React 17 or later

import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import { Footer } from "../../components/footer/footer";

const projects = [
  {
    id: "1",
    title: "Genius Fire",
    description: "A cutting-edge solution for software automation.",
    imageUrl: "/assets/projects/Portfolio/geniudFire/1.PNG", // Replace with actual image

    fullDescription:
      "Project One is a highly sophisticated software automation tool designed to streamline processes, improve efficiency, and reduce human error.",
    technologies: ["React", "Node.js", "Tailwind CSS", "Machine Learning"],
    caseStudy: {
      backGround: "Genius Fire is a comprehensive Content Management System (CMS) designed to provide detailed guidance and educational resources on fire safety precautions. Launched in response to the increasing need for accessible and reliable fire safety information, the platform serves individuals, educational institutions, and businesses seeking to enhance their knowledge and preparedness for fire-related emergencies.",
      challenge: "Prior to the development of Genius Fire, there was a notable gap in the availability of a centralized, easy-to-navigate resource that could offer up-to-date and comprehensive fire safety information. Many existing resources were fragmented, outdated, or too technical for the general public, leading to a lack of effective fire safety practices among a broad audience.",
      solution: "Genius Fire was developed to address these challenges by providing a user-friendly, interactive CMS that hosts a wide range of fire safety content. Key features of the platform include interactive guides, step-by-step emergency response techniques, educational videos, and a resource library that is regularly updated with the latest fire safety regulations and research findings. The platform also allows users to personalize their learning experience and receive notifications about updates or newly added content relevant to their interests or geographic location.",
      results: "Since its inception, Genius Fire has significantly improved the accessibility of fire safety information. The platform has been instrumental in educating its users, with a notable increase in the average session duration indicating deeper engagement with the content. Feedback from users has been overwhelmingly positive, citing the platform’s comprehensive resources and user-friendly design as crucial factors in improving their fire safety preparedness.",
      conclusion: "Genius Fire demonstrates the power of specialized CMS platforms in disseminating critical information and improving public safety. By leveraging modern web technologies and a user-centric design approach, Genius Fire has established itself as a vital tool in fire safety education. Looking ahead, the platform plans to incorporate AI-driven personalized learning paths and expand its reach to non-English speaking regions to further enhance its impact on global fire safety education."
    },
  },
  {
    id: "2",
    title: "Coffee App",
    description: "A cutting-edge solution for software automation.",
    imageUrl: "/assets/projects/Portfolio/cofeeApp/1.PNG", // Replace with actual image
    fullDescription:
      "Project One is a highly sophisticated software automation tool designed to streamline processes, improve efficiency, and reduce human error.",
    technologies: ["React", "Node.js", "Tailwind CSS", "Machine Learning"],
    caseStudy: {
      backGround: "The Coffee App is a dynamic e-commerce platform designed specifically for coffee enthusiasts. Aimed at streamlining the purchase of coffee products, this app caters to both individual consumers and wholesale buyers, offering a wide range of coffee-related products including beans, equipment, and accessories. Launched in 2023, the app serves as a one-stop-shop for all things coffee, connecting consumers directly with renowned roasters and equipment manufacturers.",
      challenge: "Prior to the development of the Coffee App, consumers faced difficulties in accessing a diverse selection of coffee products, often limited by regional availability and lack of knowledge about the different types and origins of coffee beans available on the market. Moreover, vendors struggled with reaching a broader audience, particularly small-scale roasters who did not have the resources to market their products effectively.",
      solution: "he Coffee App was developed to bridge the gap between coffee lovers and suppliers through a user-friendly, visually appealing mobile platform that enhances user engagement and simplifies the buying process. Key features include personalized recommendations based on user preferences, subscription options for regular deliveries, and detailed product descriptions with brewing tips and origin stories. Additionally, the app integrates social features that allow users to review products and share their coffee experiences with the community.",
      results: "Since its launch, the Coffee App has registered a significant increase in user engagement, with a 50% growth in monthly active users and a substantial rise in order volume. The platform has been particularly beneficial for small-scale roasters, who have seen a 40% increase in sales, demonstrating the app’s effectiveness in expanding market reach. Customer feedback highlights the app’s ease of use, informative content, and the quality of products available, affirming its position as a key player in the coffee e-commerce sector.",
      conclusion: "The Coffee App exemplifies how targeted digital solutions can transform a traditional industry, making it more accessible and engaging for consumers while providing tangible benefits to suppliers. Its success underscores the importance of understanding market needs and leveraging technology to meet those needs effectively. Moving forward, the app aims to expand its product range and explore international markets to further cement its status as a leading coffee e-commerce platform."
    },
  },
  {
    id: "3",
    title: "Scrpyard",
    description: "A cutting-edge solution for software automation.",
    imageUrl: "/assets/projects/Portfolio/Scrpyard/wc.png", // Replace with actual image
    fullDescription:
      "Project One is a highly sophisticated software automation tool designed to streamline processes, improve efficiency, and reduce human error.",
    technologies: ["React", "Node.js", "Tailwind CSS", "Machine Learning"],
    caseStudy: {
      backGround: "The Coffee App is a dynamic e-commerce platform designed specifically for coffee enthusiasts. Aimed at streamlining the purchase of coffee products, this app caters to both individual consumers and wholesale buyers, offering a wide range of coffee-related products including beans, equipment, and accessories. Launched in 2023, the app serves as a one-stop-shop for all things coffee, connecting consumers directly with renowned roasters and equipment manufacturers.",
      challenge: "Prior to the development of the Coffee App, consumers faced difficulties in accessing a diverse selection of coffee products, often limited by regional availability and lack of knowledge about the different types and origins of coffee beans available on the market. Moreover, vendors struggled with reaching a broader audience, particularly small-scale roasters who did not have the resources to market their products effectively.",
      solution: "he Coffee App was developed to bridge the gap between coffee lovers and suppliers through a user-friendly, visually appealing mobile platform that enhances user engagement and simplifies the buying process. Key features include personalized recommendations based on user preferences, subscription options for regular deliveries, and detailed product descriptions with brewing tips and origin stories. Additionally, the app integrates social features that allow users to review products and share their coffee experiences with the community.",
      results: "Since its launch, the Coffee App has registered a significant increase in user engagement, with a 50% growth in monthly active users and a substantial rise in order volume. The platform has been particularly beneficial for small-scale roasters, who have seen a 40% increase in sales, demonstrating the app’s effectiveness in expanding market reach. Customer feedback highlights the app’s ease of use, informative content, and the quality of products available, affirming its position as a key player in the coffee e-commerce sector.",
      conclusion: "The Coffee App exemplifies how targeted digital solutions can transform a traditional industry, making it more accessible and engaging for consumers while providing tangible benefits to suppliers. Its success underscores the importance of understanding market needs and leveraging technology to meet those needs effectively. Moving forward, the app aims to expand its product range and explore international markets to further cement its status as a leading coffee e-commerce platform."
    },
  },
  {
    id: "4",
    title: "Ecommerce Application",
    description: "A cutting-edge solution for software automation.",
    imageUrl: "/assets/projects/Portfolio/ecommerceApp/1.PNG", // Replace with actual image
    fullDescription:
      "Project One is a highly sophisticated software automation tool designed to streamline processes, improve efficiency, and reduce human error.",
    technologies: ["React", "Node.js", "Tailwind CSS", "Machine Learning"],
    caseStudy: {
      backGround: "The Coffee App is a dynamic e-commerce platform designed specifically for coffee enthusiasts. Aimed at streamlining the purchase of coffee products, this app caters to both individual consumers and wholesale buyers, offering a wide range of coffee-related products including beans, equipment, and accessories. Launched in 2023, the app serves as a one-stop-shop for all things coffee, connecting consumers directly with renowned roasters and equipment manufacturers.",
      challenge: "Prior to the development of the Coffee App, consumers faced difficulties in accessing a diverse selection of coffee products, often limited by regional availability and lack of knowledge about the different types and origins of coffee beans available on the market. Moreover, vendors struggled with reaching a broader audience, particularly small-scale roasters who did not have the resources to market their products effectively.",
      solution: "he Coffee App was developed to bridge the gap between coffee lovers and suppliers through a user-friendly, visually appealing mobile platform that enhances user engagement and simplifies the buying process. Key features include personalized recommendations based on user preferences, subscription options for regular deliveries, and detailed product descriptions with brewing tips and origin stories. Additionally, the app integrates social features that allow users to review products and share their coffee experiences with the community.",
      results: "Since its launch, the Coffee App has registered a significant increase in user engagement, with a 50% growth in monthly active users and a substantial rise in order volume. The platform has been particularly beneficial for small-scale roasters, who have seen a 40% increase in sales, demonstrating the app’s effectiveness in expanding market reach. Customer feedback highlights the app’s ease of use, informative content, and the quality of products available, affirming its position as a key player in the coffee e-commerce sector.",
      conclusion: "The Coffee App exemplifies how targeted digital solutions can transform a traditional industry, making it more accessible and engaging for consumers while providing tangible benefits to suppliers. Its success underscores the importance of understanding market needs and leveraging technology to meet those needs effectively. Moving forward, the app aims to expand its product range and explore international markets to further cement its status as a leading coffee e-commerce platform."
    },
  },
  // ... other projects
];

export default function ProjectDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const project: any = projects.filter((p) => p.id == id);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div>
      <Navbar classes="bg-[#1F2937]"></Navbar>

      <div className="min-h-screen bg-[#094D5A] py-12 px-6 lg:px-20">
        <div className="flex justify-end" >
          <button
            onClick={() => {
              navigate("/projects");
            }}
            className="mb-6 bg-[#1F2937] text-white font-semibold  py-2 px-4 rounded-lg shadow-md hover:opacity-70"
          >
            All Projects
          </button>
        </div>
        <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
          <img
            src={project[0].imageUrl}
            alt={project[0].title}
            className="h-64 w-full object-cover"
          />
          <div className="p-6">
            <h2 className="text-3xl font-bold text-[#094D5A]">
              {project[0].title} - Case Study
            </h2>
            <div className="mt-6">
             
              <div className="my-4">
                <h4 className="text-4xl font-semibold text-[#094D5A]">
                  
                </h4>
                <h1 className="font-semibold py-2 text-xl">Background</h1>
                <p>{project[0].caseStudy.backGround}</p>
                <h1 className="font-semibold py-2 text-xl">Challenge</h1>
                <p>{project[0].caseStudy.challenge}</p>
                <h1 className="font-semibold py-2 text-xl">Solution</h1>
                <p>{project[0].caseStudy.solution}</p>
                <h1 className="font-semibold py-2 text-xl">Results</h1>
                <p>{project[0].caseStudy.results}</p>
                <h1 className="font-semibold py-2 text-xl">Conclusion</h1>
                <p>{project[0].caseStudy.conclusion}</p>
              </div>
              <h4 className="text-2xl font-semibold text-[#094D5A]">
                Technologies Used
              </h4>
              <ul className="mt-3 space-y-2">
                {project[0].technologies.map((tech: string, index: any) => (
                  <li
                    key={index}
                    className="inline-block bg-gray-100 text-gray-800 py-1 px-3 rounded-full"
                  >
                    {tech}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
