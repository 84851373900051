import { useLocation } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import { Footer } from "../../components/footer/footer";

export const ServiceDetailPage = () => {
  const location = useLocation();
  const { props } = location.state || {}; // Access props

  return (
    <div>
      <Navbar classes='bg-[#1F2937]'></Navbar>
      {props ? (
        <div className="container  py-28 px-[3rem] lg:px-[9rem] md:px-[4rem] bg-slate-300">
          <div className="px-[8rem] flex flex-col text-center">
            <h2 className="font-bold tracking-tight pt-5 text-3xl lg:text-4xl">{props.title}</h2>
            <p className="pt-10">{props.description}</p>
            <img src={props.imageUrl} alt="Dynamic" className="h-auto w-full pt-10 rounded-3xl" />
            <p className="pt-10">{props.description}</p>
            <p className="pt-10">{props.description}</p>
            <p className="pt-10">{props.description}</p>
          </div>
        </div>
      ) : (
        <p>No data found</p>
      )}

      <Footer></Footer>
    </div>
  );
}