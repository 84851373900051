import { Footer } from "../footer/footer"
import Navbar from "../navbar/navbar"

interface childrenProps {
	children?: React.ReactNode;
	classes?: string;
}

export const Layout: React.FC<childrenProps> = ({ children, classes }) => {
	return (
		<>
			<Navbar classes={classes} />
			{children}
			<Footer />
		</>
	)
}