import React, { useState, useEffect, useRef } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import Card from "../../components/card/card";
import visionImage from "../public/assets/vision.jpg";
import { Footer } from "../../components/footer/footer";
import OurProjects from "../../components/ourProjects/ourProjects";
import { CallBackForm } from "../../components/callBackForm/callBackForm";
import { Layout } from "../../components/layout/Layout";

interface Slide {
  image: string;
  text: string;
  desc: any;
}


const slides: Slide[] = [
  {
    image: '/assets/webAppDevelopment.jpg',
    text: 'Web Development',
    desc: (
      <div>
        <p>
          We specialize in crafting high-quality, user-friendly web applications that drive business success. Whether you're launching a new platform or reimagining an existing one, we offer comprehensive web development services tailored to meet your unique needs.
        </p>
        <p className='pt-3'>
          From intuitive interfaces to secure, high-performance backend systems, we build applications that are responsive, reliable, and designed for future growth. By focusing on user experience and leveraging the latest web technologies, we help businesses transform their ideas into powerful, effective digital solutions that make an impact.
        </p>
      </div>
    )
  },
  {
    image: '/assets/mobileAppDevelopment.jpg',
    text: 'Mobile App Development',
    desc: (
      <div>
        <p>
          We provide top-tier mobile app development services designed to help businesses connect with their audience in an increasingly mobile-driven world. Whether you're creating a new app from the ground up or looking to enhance an existing one, we offer custom mobile solutions that cater to your unique business needs.
        </p>
        <p className='pt-3'>
          We create user-centric, intuitive mobile apps optimized for performance, security, and usability. From concept to deployment, we turn your vision into a high-quality, market-ready application, whether you're launching a new app or expanding your mobile presence.
        </p>
      </div>
    )
  },
  {
    image: '/assets/cloudComputing.jpg',
    text: 'Cloud Solutions',
    desc: (
      <div>
        <p>
          We provide innovative cloud solutions designed to transform how your business operates. Whether you're looking to migrate to the cloud, optimize your current setup, or leverage cloud infrastructure for growth, our tailored services deliver flexibility, scalability, and security.
        </p>
        <p className='pt-3'>
          We work with leading cloud platforms like AWS, Google Cloud, and Microsoft Azure, enabling businesses to scale effortlessly, improve operational agility, and reduce costs. Our deep knowledge of cloud architecture allows us to design solutions that ensure high availability, data security, and flexibility to support your growth.
        </p>
      </div>
    )
  },
  {
    image: '/assets/shopifyManagement.jpg',
    text: 'Shopify Management',
    desc: (
      <div>
        <p>
          We provide comprehensive Shopify management services to help businesses streamline their eCommerce operations and maximize sales. From store setup and design customization to inventory management, payment integration, and ongoing optimization, we handle all aspects of your Shopify store.
        </p>

        <p className='pt-3'>
          Our team ensures your platform is visually appealing, user-friendly, and fully optimized for performance, enabling you to focus on growing your business while we take care of the technical details. Let us help you unlock the full potential of Shopify and drive sustainable growth for your online store.
        </p>
      </div>
    )
  },
  {
    image: '/assets/wordpress-development.jpg',
    text: 'WordPress development',
    desc: (
      <div>
        <p>
          We offer expert WordPress development services to create custom websites that are both visually appealing and highly functional. Whether you're building a new site or enhancing an existing one, we tailor solutions to fit your business needs, ensuring speed, security, and scalability.
        </p>
        <p className='pt-3'>
          From theme customization to plugin integration, we make your WordPress site easy to manage and optimized for performance. Our goal is to help you stand out online and drive growth with a seamless user experience.
        </p>
      </div>
    )
  },
  {
    image: '/assets/graphicAndVideoEditing.jpg',
    text: 'Graphic and video Editing',
    desc: (
      <div>
        <p>
          We provide professional graphic and video editing services designed to elevate your brand’s visual presence. Whether you need stunning visuals for marketing campaigns, social media, or promotional content, our team delivers high-quality, engaging designs that capture your audience's attention.
        </p>
        <p className='pt-3'>
          From logo design and custom graphics to professional video editing, we bring your vision to life with creativity and precision. Our goal is to help your brand communicate its message effectively through captivating visuals that leave a lasting impact.
        </p>
      </div>
    )
  },
];

const LandingPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef<number | null>(null);
  const navigate = useNavigate();

  // Auto-scroll effect
  useEffect(() => {
    timeoutRef.current = window.setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change slide every 3 seconds

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clear timeout on component unmount
      }
    };
  }, [currentIndex]);

  const ExploreMore = () => {
    navigate('/ExploreMore');
  }

  return (
    <>
      <Layout>
        <div className="bg-[url('../public/assets/bg-cover4.jpg')] bg-cover bg-center h-[100vh] w-full">

          <div className="container h-full flex flex-col justify-center max-w-7xl mx-auto px-3 md:px-8 pt-[3.4rem]">
            <h2 className="px-3 font-medium uppercase tracking-tight text-white text-2xl md:text-3xl lg:text-4xl">
              Aiming to <span className="inline-block text-[#ac7ae1]">empower</span>
              <span className="block py-0 md:py-3">
                the <span className="inline-block text-[#ac7ae1]">world</span> through <span className="inline-block text-[#ac7ae1]">technology</span>
              </span>
            </h2>
            <p className="p-3 pt-[1rem] max-w-2xl text-white text-sm md:text-xl">
              Looking for a software partner who truly understands your business
              needs, speaks beyond lines of code and deliveries state-of-the art
              products?
            </p>

            <div className="flex justify-start p-3 md:mt-[1rem]">
              <button className="px-3 py-1 md:px-4 md:py-2  mt-3 bg-[#603F83] rounded hover:bg-black-700 text-white text-lg md:text-xl" onClick={ExploreMore}>
                Let's Talk
              </button>
            </div>
          </div>
        </div>

        <div className="container mx-auto max-w-full bg-gray-100 pt-8 pb-14 px-[3rem] lg:px-[9rem] md:px-[4rem] ">
          <div className="grid grid-cols-1 md:grid-cols-2 md-sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <Card
              title="Mobile App Development"
              description="Codehexa Innovation is an IT service provider that specializes in custom mobile app development. We use our years of experience and the latest technology to make native apps for both Android and iOS that are engaging, easy to use, and full of features. We have the technical know-how and project management skills to deliver results, whether you want a simple MVP to test a concept or a complex enterprise-level solution."
              iconUrl="/assets/app-development.png"
              imageUrl="/assets/mobile-app-dev.jpg"
            />
            <Card
              title="Web App Development"
              description="Codehexa Innovation’s team of experienced engineers will design, build, and deploy web applications that will take your vision for a web application and make it a reality. We specialize in creating custom-built solutions based on the latest technologies. Our scalable architectures are designed to work with some of the biggest cloud providers - ensuring reliable, secure performance with low latency."
              iconUrl="/assets/web-development.png"
              imageUrl="/assets/web-app-dev.jpg"
            />
            <Card
              title="Cloud Consulting"
              description="Codehexa Inovation Cloud Consulting is a full-service IT company that focuses
           on cloud services and consulting. We help businesses get the most out of their technology
            investments so that their processes work as well as possible."
              iconUrl="/assets/cloud-computing.png"
              imageUrl="/assets/cloud-consulting.jpg"
            />
            <Card
              title="Software Product Engineering"
              description="Codehexa Inovation offers the perfect IT Outsourcing and Software Development package for all your software product engineering needs. We specialize in developing custom software solutions that solve real-world, mission-critical problems. With over 9 years of experience and a team of seasoned professionals, Codehexa Innovation can help you deliver on time, every time."
              iconUrl="/assets/computing.png"
              imageUrl="/assets/software-product-engineering.jpg"
            />
          </div>
        </div>

        <div className="container h-full flex flex-col justify-center items-center mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 className="p-2 pt-[5rem] font-bold tracking-tight text-3xl lg:text-4xl">
            Our Core Values
          </h2>
          <p className="text-sm text-base pb-[3rem] font-light">
            Empowering the World Through Technology
          </p>
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="p-4">
                <img
                  src="/assets/core-values.png"
                  alt="Dynamic"
                  className=" h-auto w-full"
                />
              </div>
              <div className="p-4">
                <p className="text-sm font-light">
                  Our recipe for success has always been hard work, determination,
                  and building strong individuals and teams. We believe the
                  biggest asset for any organization is its workforce and team. We
                  believe in leading from the front, developing a good
                  relationship with the team members, and pushing people to think
                  beyond their limits. We want to continue our journey to empower
                  the world through technology and thrive on constant innovation.
                  We at Codehexa Inovation would rather miss out on a lot of
                  opportunities to focus on providing quality work to those we
                  work for!
                </p>
                <h2 className="text-2xl pt-5 antialiased font-semibold leading-snug tracking-normal">
                  Our Mission
                </h2>
                <h2 className="text-l pt-4 font-semibold">
                  Dedication to Customer Service
                </h2>
                <p className="text-sm pt-2 font-light">
                  Our mission is to provide a world-class customer service
                  experience by not only meeting, but exceeding our customers’
                  expectations.
                </p>

                <h2 className="text-l pt-6 font-semibold">Quality of Delivery</h2>
                <p className="text-sm pt-2 font-light">
                  We try to always give results that are better than the highest
                  standards for quality and accuracy and show that we are
                  committed to the best practices and policies of our industry.
                </p>

                <h2 className="text-l pt-6 font-semibold">
                  Commitment to Innovation
                </h2>
                <p className="text-sm pt-2 font-light">
                  We want to stay at the top of the Tech-Industry by staying ahead
                  of trends and keeping an open line of communication with our
                  clients about their future needs.
                </p>

                <h2 className="text-l pt-6 font-semibold">
                  Accountability and transparency
                </h2>
                <p className="text-sm pt-2 font-light">
                  Because we are committed to getting measurable results, we are
                  responsible for each one of our projects from start to finish.
                  The development team keeps in touch with us throughout the whole
                  process.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-[4rem] md:pt-[5rem] h-full flex flex-col justify-center items-center mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 className="p-3 text-2xl px-[2rem] text-center font-bold tracking-tight md:text-3xl lg:text-4xl">
            Why to Choose Codehexa Innovation
          </h2>
          <p className="text-sm px-[3rem] text-center text-base pb-[3rem] font-light">
            as Your IT, Software Development and Managed Services Company
          </p>

          <p className="text-sm px-8 font-light text-center">
            Codehexa Innovation is a Australia, US & Pakistan based IT, Software
            Development, and Managed Services Company that consults with top
            organizations to maximize their success. Our professionals have a lot
            of experience, so they can make solutions that fit the needs of each
            industry partner. With our reliable managed services, businesses can
            make the most of what they already have and get the most out of their
            data systems. Get consultations from our experts today and join a
            top-rated company in Australia, US & Pakistan!
          </p>
        </div>
        <div className="container mx-auto px-4 pt-[5rem] max-w-7xl sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="p-4">
              <h2 className="pt-[1.8rem] md:pt-[3rem] font-bold tracking-tight text-3xl lg:text-4xl">
                What We Offer
              </h2>
              <p className="text-sm text-base pb-[3rem] font-light">
                As always, partners grows with you!
              </p>
              <div
                id="default-carousel"
                className="relative w-full overflow-hidden"
              >
                <div
                  className="flex transition-transform duration-700 ease-in-out"
                  style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                  {slides.map((slide, index) => (
                    <div
                      key={index}
                      className="relative w-full flex-shrink-0 h-56 md:h-96"
                    >
                      <div className="top-0 left-0 text-black text-2xl absolute">
                        <h3>{slide.text}</h3>
                        <p className="text-sm font-light pt-4">{slide.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="p-4 flex items-center">
              <div
                id="default-carousel"
                className="relative w-full overflow-hidden"
              >
                <div
                  className="flex transition-transform duration-700 ease-in-out"
                  style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                  {slides.map((slide, index) => (
                    <div
                      key={index}
                      className="relative w-full flex-shrink-0 h-56 md:h-96"
                    >
                      <img
                        src={slide.image}
                        className="mx-auto max-w-full h-full object-cover"
                        alt={`Slide ${index + 1}`}
                      />
                    </div>
                  ))}
                </div>

                {/* Slider indicators */}
                <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
                  {slides.map((_, index) => (
                    <button
                      key={index}
                      type="button"
                      className={`w-3 h-3 rounded-full ${index === currentIndex ? "bg-white" : "bg-gray-400"
                        }`}
                      aria-current={index === currentIndex}
                      aria-label={`Slide ${index + 1}`}
                      onClick={() => setCurrentIndex(index)}
                      data-carousel-slide-to={index}
                    ></button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container h-full flex flex-col justify-center items-center mt-14  pt-[5rem] md:pt-0  mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 className="p-3 font-bold tracking-tight  text-3xl lg:text-4xl">
            Our Projects
          </h2>
          <p className="text-sm text-base font-light">
            As always, partners grow with you!
          </p>
        </div>
        <OurProjects></OurProjects> */}
        <div className="bg-[#EAEDFE]  px-16 md:flex">
          <img src="/assets/detail.png" className=""></img>
          <div className="flex justify-center items-center w-full">
            <div className="sm:px-12 px-2 pb-5">
              <p className="text-[#351A5F] text-2xl font-bold">Software service for <br></br> your business. </p>
              <p className="text-gray-600 pt-3">
                We provide innovative software solutions tailored to meet your business needs, specializing in custom development, web applications, and cloud-based services. Our expert team leverages cutting-edge technology to drive efficiency, scalability, and long-term success for businesses across various industries.
              </p>
            </div>
          </div>
        </div>



        <div className="relative h-[80vh] md:h-[70vh] w-full bg-fixed bg-center">
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: `url('/assets/vision.jpg')`,
            }}
          ></div>

          <div className="absolute inset-0 bg-black opacity-50"></div>

          <div className="relative z-9 container h-full flex flex-col justify-center items-center mx-auto max-w-7xl sm:px-6 lg:px-8">
            <h2 className="p-3 pt-[5rem] pb-[2rem] font-bold text-white tracking-tight text-3xl lg:text-4xl">
              Our Vision
            </h2>
            <p className="text-sm px-[2rem] text-center pb-[3rem] font-light text-white">
              Our Vision is dedicated to creating innovative solutions and giving
              our customers the highest quality IT technology products. We strive
              to be a team that puts the success of our clients first and designs
              custom IT products. With robust research and development, we design
              and deliver advanced products and services for companies and
              individuals striving for technological advancements in their
              industry. We are passionate about discovering new approaches that
              can revolutionize the way people operate their businesses and lives
              and ultimately create meaningful changes towards achieving progress.
            </p>
          </div>
        </div>

        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <img src="/assets/contactImage.png" className=""></img>
            </div>
            <div className="px-[4rem]">
              <h2 className="pt-[6rem] font-bold tracking-tight text-3xl lg:text-4xl">
                Request a Call Back
              </h2>
              <p className="text-sm pt-3 text-base pb-[3rem] font-light">
                Our financial advisers always help you
              </p>

              <CallBackForm />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default LandingPage;
