import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './views/landingPage/landingPage';
import Navbar from './components/navbar/navbar';
import AboutUs from './views/aboutus/aboutUs';
import { ServiceDetailPage } from './views/serviceDetailPage/serviceDetailPage';
import { ExporeMore } from './components/exploreMore/exploreMore';
import ProjectShowcase from './views/projectsListing/projectsListing';
import ProjectDetail from './views/projectDetail/projectDetail';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ServiceDetail" element={<ServiceDetailPage />} />
        <Route path="/Projects" element={<ProjectShowcase />} />
        <Route path="/ProjectDetail/:id" element={<ProjectDetail />} />
        <Route path="/ExploreMore" element={<ExporeMore />}/>
      </Routes>
    </BrowserRouter>
  );
}

// Ensure the root element is non-null before creating the root.
const rootElement = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);
