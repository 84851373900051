// ProjectShowcase.jsx

import React from "react";
import Navbar from "../../components/navbar/navbar";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components/footer/footer";

const projects = [
  {
    id: 1,
    title: "Genius Fire",
    description:
      "A Content Management System that provides saftey precautionary measures to the users.",
    imageUrl: "/assets/projects/Portfolio/geniudFire/1.PNG", // Replace with actual image
  },
  {
    id: 2,
    title: "Coffee App",
    description: "An Ecommerce app for the Cofee items.",
    imageUrl: "/assets/projects/Portfolio/cofeeApp/1.PNG", // Replace with actual image
  },
  {
    id: 3,
    title: "Scrpyard",
    description: "A responsive web application for businesses.",
    imageUrl: "/assets/projects/Portfolio/Scrpyard/wc.png", // Replace with actual image
  },
  {
    id: 4,
    title: "Ecommerce Application",
    description: "A responsive web application for businesses.",
    imageUrl: "/assets/projects/Portfolio/ecommerceApp/1.PNG", // Replace with actual image
  },
];

const ProjectShowcase = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar classes="bg-[#1F2937]"></Navbar>
      <div className="min-h-screen  bg-[#094D5A] py-24 px-6 lg:px-20">
        <div className="md:flex bg-[#141A28] rounded-2xl mb-5">
          <div className="p-10">
            <h1 className="text-white text-4xl font-bold text-center mb-10">
              CodeHexa Innovation Projects
            </h1>
            <p className="text-white">
              {" "}
              Each project listed showcases our adept use of advanced software
              tools and technologies to
              achieve specific business objectives. Our projects emphasize
              collaboration with cross-functional teams, underscoring our
              commitment to communication and teamwork. These initiatives are
              aligned with current industry trends, demonstrating our proactive
              approach to leveraging cutting-edge technology for impactful
              results.
            </p>
          </div>
          <img
            src="/assets/portImage1.png"
            className="h-[30vh] md:h-[60vh] flex justify-center items-center w-[100%]"
          ></img>
        </div>
        <div
          
          className="grid cursor-pointer gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
        >
          {projects.map((project) => (
            <div
            onClick={() => {
                navigate(`/ProjectDetail/${project.id}`);
              }}
              key={project.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <img
                src={project.imageUrl}
                alt={project.title}
                className="h-56 w-full object-cover "
              />
              <div className="p-6">
                <h3 className="text-2xl font-semibold text-[#094D5A]">
                  {project.title}
                </h3>
                <p className="mt-3 text-gray-700">{project.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ProjectShowcase;
