import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react"; // Assuming you're using Headless UI's Disclosure component
import { Link } from "react-router-dom"; // React Router Link
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"; // Assuming you're using HeroIcons

interface NavbarProps {
  classes?: string; // Define the type for classes
}

const Navbar: React.FC<NavbarProps> = ({ classes }) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [top, setTop] = useState<boolean>(true);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
    if (position === 0) {
      setTop(true);
    } else if (position > 10) {
      setTop(false);
    } else if (position < 10) {
      setTop(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]); // Dependency array includes scrollPosition

  return (
    <Disclosure as="nav" className={`fixed top-0 w-full z-10 transition-all duration-300 ease-in-out ${classes} bg-white shadow-lg
      }`}>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-[9vh] items-center justify-between">
          <Link to="/">
            <div className="flex-shrink-0 pt-3 ps-3">
                <img
                  alt="Your Company"
                  src="/assets/color-logo-with-side-text.png"
                  className="h-[7vh] pb-2"
                />
            </div>
          </Link>

          <div className="hidden sm:ml-6 sm:block">
            <ul className="flex space-x-4">
              <Link to="/">
                <li className={`rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-[#603F83] hover:text-white`}>Dashboard</li>
              </Link>
              <Link to="/aboutUs">
                <li className={`rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-[#603F83] hover:text-white`}>Team</li>
              </Link>
              <Link to="/projects">
                <li className={`rounded-md px-3 py-2 text-sm font-medium text-black hover:bg-[#603F83] hover:text-white`}>Projects</li>
              </Link>
            </ul>
          </div>

          <div className="mr-2 flex sm:hidden">
            {/* Mobile menu button */}
            <Disclosure.Button className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
              <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
            </Disclosure.Button>
          </div>
        </div>
      </div>

      <Disclosure.Panel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          <Disclosure.Button
            as={Link}
            to="/"
            className="block rounded-md bg-gray-900 px-3 py-2 text-base font-medium text-white"
          >
            Dashboard
          </Disclosure.Button>
          <Disclosure.Button
            as={Link}
            to="/aboutUs"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Team
          </Disclosure.Button>
          <Disclosure.Button
            as={Link}
            to="/projects"
            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
          >
            Projects
          </Disclosure.Button>

        </div>
      </Disclosure.Panel>
    </Disclosure >
  );
};

export default Navbar;

