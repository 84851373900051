import { useState } from "react";
import { CallBackForm } from "../callBackForm/callBackForm"
import { Footer } from "../footer/footer";
import Navbar from "../navbar/navbar"
import { Layout } from "../layout/Layout";

interface FormData {
  name: string;
  email: string;
  phoneNumber: string;
  technologyPreference: string;
  pursoseOfWebsite: string;
  budgetRange: string;
  projectTimeFrame: string;
  file: File | null;
  projectDetail: string;
}

export const ExporeMore = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phoneNumber: '',
    technologyPreference: 'USA',
    pursoseOfWebsite: '',
    budgetRange: '',
    projectTimeFrame: '',
    file: null,
    projectDetail: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFormData({
        ...formData,
        file: e.target.files[0],
      });
    }
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      projectDetail: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    console.log('Form submitted:', formData);
    // try {
    //   const response = await fetch('https://api.sendgrid.com/v3/mail/send', {
    //     method: 'POST',
    //     headers: {
    //       'Authorization': `Bearer YOUR_SENDGRID_API_KEY`, // Replace with your SendGrid API Key
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //       personalizations: [
    //         {
    //           to: [{ email: 'rabiaadeel904@gmail.com' }], // Replace with the recipient's email
    //           subject: formData.pursoseOfWebsite,
    //         },
    //       ],
    //       from: { email: formData.email },
    //       content: [
    //         {
    //           type: 'text/plain',
    //           value: formData,
    //         },
    //       ],
    //     }),
    //   });

    //   if (response.ok) {
    //     alert('Email sent successfully!');
    //   } else {
    //     alert('Failed to send email.');
    //   }
    // } catch (error) {
    //   console.error('Error:', error);
    //   alert('An error occurred while sending the email.');
    // }
  };

  return (
    <>
      <Layout classes='bg-[#1F2937]'>
        <form onSubmit={handleSubmit} action="https://formsubmit.co/rabiaadeel904@email.com" method="POST" className="max-w-7xl mx-auto p-8">
          <div className="flex mt-[6rem]">
            <div className="w-1/2 p-4">
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="appearance-none h-12 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phoneNumber" className="block text-gray-700 text-sm font-bold mb-2">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-full h-12 rounded border border-gray-300 p-3 focus:outline-none"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="selection" className="block text-gray-700 text-sm font-bold mb-2">
                  Purpose of the website
                </label>
                <select
                  id="pursoseOfWebsite"
                  name="pursoseOfWebsite"
                  value={formData.pursoseOfWebsite}
                  onChange={handleSelectChange}
                  className="w-full h-12 rounded border border-gray-300 text-gray-700 focus:outline-none bg-gray-50 text-sm block p-3"
                >
                  <option value="">Purpose of the website</option>
                  <option value="Personal">Personal</option>
                  <option value="Business">Business</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="selection" className="block text-gray-700 text-sm font-bold mb-2">
                  Project timeframe
                </label>
                <select
                  id="projectTimeFrame"
                  name="projectTimeFrame"
                  value={formData.projectTimeFrame}
                  onChange={handleSelectChange}
                  className="w-full h-12 rounded border border-gray-300 text-gray-700 p-3 focus:outline-none bg-gray-50 text-sm block p-3"
                >
                  <option value="">Project TimeFrame</option>
                  <option value="Immediately">Immediately</option>
                  <option value="1-3 Months">1-3 Months</option>
                  <option value="More than 3 months">More than 3 months</option>
                </select>
              </div>
            </div>

            <div className="w-1/2 p-4">
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="appearance-none h-12 rounded border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="selection" className="block text-gray-700 text-sm font-bold mb-2">
                  Technology preference
                </label>
                <select
                  id="technologyPreference"
                  name="technologyPreference"
                  value={formData.technologyPreference}
                  onChange={handleSelectChange}
                  className="w-full h-12 rounded border border-gray-300 text-gray-700 p-3 focus:outline-none bg-gray-50 text-sm block p-3"
                >
                  <option value="">Select Technology preference</option>
                  <option value="Option 1">Full Stack Web Development</option>
                  <option value="Option 2">Ruby on Rails</option>
                  <option value="Option 3">PHP Joomla</option>
                  <option value="Option 4">HTML / HTML5</option>
                  <option value="Option 5">Relational & Non-Relational Databases</option>
                  <option value="Option 6">Server Setup & Management</option>
                  <option value="Option 7">Mobile App Development</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="selection" className="block text-gray-700 text-sm font-bold mb-2">
                  Budget range
                </label>
                <select
                  id="budgetRange"
                  name="budgetRange"
                  value={formData.budgetRange}
                  onChange={handleSelectChange}
                  className="w-full h-12 rounded border border-gray-300 text-gray-700 p-3 focus:outline-none bg-gray-50 text-sm block p-3"
                >
                  <option value="">Budget Range</option>
                  <option value="$5,001 - $10,000">$5,001 - $10,000</option>
                  <option value="$10,001 - $20,000">$10,001 - $20,000</option>
                  <option value="$20,001 - $50,000">$20,001 - $50,000</option>
                  <option value="over $50,000">over $50,000</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="file" className="block text-gray-700 text-sm font-bold mb-2">
                  Choose a File
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={handleFileChange}
                  className="w-full h-12 rounded border border-gray-300 p-3 text-gray-700 focus:outline-none text-sm block"
                />
              </div>
            </div>
          </div>

          <div className="w-full pt-4 px-4">
            <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">
              Project Detail
            </label>
            <textarea
              id="projectDetail"
              value={formData.projectDetail}
              onChange={handleTextareaChange}
              className="w-full h-32 rounded border border-gray-300 py-2 px-3 text-gray-700 focus:outline-none"
            />
          </div>
          <div className="mb-4 px-4">
            <button type="submit" className="px-3 py-1 rounded md:px-4 md:py-2  mt-5 bg-[#603F83] hover:bg-black-700 text-white text-sm py-2 px-4 focus:outline-none">
              Get a Quote
            </button>
          </div>
        </form>
      </Layout>
    </>
  )
}